
.ui.table {
  border: 2px;
  outline-style: double;
}

.ui.table tr {
}

.ui.table td {
  border-top: 1px solid black ;
  /* padding-top: 3px;
  padding-bottom: 3px; */
  padding: 3px;
}

.ui.table td,
.ui.table th {
  text-align: center;
  height: 45px;
  color: #BD9A9C;
}
.ui.table thead th {
  color: #BD9A9C;
  padding-left: 0;
  padding: 10px 0 10px 0 !important;
  position: relative;
}
.ui.table thead th.active {
  color: black;
}

.ui.table th.active .active-user-sign {
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #000000 transparent transparent transparent;
}



/* .ui.table th.user-info {
  width: 40px;
  padding: 0;
  padding-top: 10px;
  vertical-align: middle;
} */

.ui.table .score {
  width: 140px;
  text-align: left;
  padding-left: 10px !important;
  color: black;
}
.ui.table td.active {
  background: transparent!important;
  color: black;
}
.ui.table tr.total td,
.ui.table tr.bonus td  {
  font-weight: 700;
  border-bottom: 2px solid black;
}
.ui.table td.active-selection {
  padding: 0;
}
.ui.table td.active-selection button {
  height: 30px;
  width: 30px;
  background: white;
  border: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 50%;
}
.ui.table td.active-selection button:hover {
  background: rgba(255,255,255, 0.5);
}
.scoreboard {
  margin-top: 30px;
}
