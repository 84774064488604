body {
  background-color: #FFDADD;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.outer {
  margin: 0 auto;
  text-align: center;
  padding: 50px 20px 30px 20px;
}


::-moz-selection { /* Code for Firefox */
  color: white;
  background: #584143;
}
::selection {
  color: white;
  background: #584143;
}

.logo {
  margin-top: 50px;
  margin-bottom: 80px;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
}

/* BUTTONS */

.button{
  transition: background 500ms;
}

.ui.button {
  display: inline-block;
  padding: 15px 40px;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  margin-top: 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: bold;
  background: black;
  color: #BD9A9C;
}
.ui.button:focus,
.ui.button:active {
  background: black;
  color: #BD9A9C;
}

.ui.button:hover{
  background: #584143;
  color: #BD9A9C;
}

.ui.button.welcome {
  width: 250px;

}



/* special icon-button */
.button.button-icon {
  background: transparent;
  border: none;
  color: #BD9A9C;
  text-transform: none;
  letter-spacing: 0;
}
.button-icon span {
  bottom: -14px;
  position: relative;
  margin-right: 10px;
}

.button.button-icon:hover {
  background: transparent;
  border: none;
  color: black;
}
.button.button-icon:active,
.button.button-icon:focus {
  background: transparent;
}

.button-icon svg path,
.button-icon svg circle {
  stroke: #BD9A9C;
}
.button-icon:hover svg path,
.button-icon:hover svg circle {
  stroke: black;
}

/* input fields */
input[type=text] {
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  box-shadow: none;
  font-size: 30px;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  margin-top: 20px;
  outline: none;
  max-width: 100%;
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BD9A9C;
  opacity: 1; /* Firefox */
}

/* Forms */

ul.player-list {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

ol.player-ranks {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

/* DICE */
.message-area {
  min-height: 100px;
}

.dices {
  display: flex;
  max-width: 700px;
  margin: 0 auto;
}
.dice {
  flex-grow: 1;

  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}
.dice:last-of-type {
  margin-right: 0;
}

.dice svg {
  width: 100%;
  height: auto;
}


@media screen and (min-width: 992px) {
  .dices {
    /* max-width: 900px; */
  }
}
