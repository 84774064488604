body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.ui.table {
  border: 2px;
  outline-style: double;
}

.ui.table tr {
}

.ui.table td {
  border-top: 1px solid black ;
  /* padding-top: 3px;
  padding-bottom: 3px; */
  padding: 3px;
}

.ui.table td,
.ui.table th {
  text-align: center;
  height: 45px;
  color: #BD9A9C;
}
.ui.table thead th {
  color: #BD9A9C;
  padding-left: 0;
  padding: 10px 0 10px 0 !important;
  position: relative;
}
.ui.table thead th.active {
  color: black;
}

.ui.table th.active .active-user-sign {
  position: absolute;
  top: -10px;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-style: solid;
  border-width: 20px 20px 0 20px;
  border-color: #000000 transparent transparent transparent;
}



/* .ui.table th.user-info {
  width: 40px;
  padding: 0;
  padding-top: 10px;
  vertical-align: middle;
} */

.ui.table .score {
  width: 140px;
  text-align: left;
  padding-left: 10px !important;
  color: black;
}
.ui.table td.active {
  background: transparent!important;
  color: black;
}
.ui.table tr.total td,
.ui.table tr.bonus td  {
  font-weight: 700;
  border-bottom: 2px solid black;
}
.ui.table td.active-selection {
  padding: 0;
}
.ui.table td.active-selection button {
  height: 30px;
  width: 30px;
  background: white;
  border: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-radius: 50%;
}
.ui.table td.active-selection button:hover {
  background: rgba(255,255,255, 0.5);
}
.scoreboard {
  margin-top: 30px;
}

body {
  background-color: #FFDADD;
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 16px;
}

.outer {
  margin: 0 auto;
  text-align: center;
  padding: 50px 20px 30px 20px;
}


::-moz-selection { /* Code for Firefox */
  color: white;
  background: #584143;
}
::selection {
  color: white;
  background: #584143;
}

.logo {
  margin-top: 50px;
  margin-bottom: 80px;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
}

/* BUTTONS */

.button{
  -webkit-transition: background 500ms;
  transition: background 500ms;
}

.ui.button {
  display: inline-block;
  padding: 15px 40px;
  border: none;
  cursor: pointer;
  border-radius: 40px;
  margin-top: 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: bold;
  background: black;
  color: #BD9A9C;
}
.ui.button:focus,
.ui.button:active {
  background: black;
  color: #BD9A9C;
}

.ui.button:hover{
  background: #584143;
  color: #BD9A9C;
}

.ui.button.welcome {
  width: 250px;

}



/* special icon-button */
.button.button-icon {
  background: transparent;
  border: none;
  color: #BD9A9C;
  text-transform: none;
  letter-spacing: 0;
}
.button-icon span {
  bottom: -14px;
  position: relative;
  margin-right: 10px;
}

.button.button-icon:hover {
  background: transparent;
  border: none;
  color: black;
}
.button.button-icon:active,
.button.button-icon:focus {
  background: transparent;
}

.button-icon svg path,
.button-icon svg circle {
  stroke: #BD9A9C;
}
.button-icon:hover svg path,
.button-icon:hover svg circle {
  stroke: black;
}

/* input fields */
input[type=text] {
  border: none;
  border-bottom: 2px solid black;
  background-color: transparent;
  box-shadow: none;
  font-size: 30px;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  margin-top: 20px;
  outline: none;
  max-width: 100%;
}


::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BD9A9C;
  opacity: 1; /* Firefox */
}


::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BD9A9C;
  opacity: 1; /* Firefox */
}


::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BD9A9C;
  opacity: 1; /* Firefox */
}

/* Forms */

ul.player-list {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

ol.player-ranks {
  display: inline-block;
  text-align: left;
  margin: 0 auto;
}

/* DICE */
.message-area {
  min-height: 100px;
}

.dices {
  display: -webkit-flex;
  display: flex;
  max-width: 700px;
  margin: 0 auto;
}
.dice {
  -webkit-flex-grow: 1;
          flex-grow: 1;

  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}
.dice:last-of-type {
  margin-right: 0;
}

.dice svg {
  width: 100%;
  height: auto;
}


@media screen and (min-width: 992px) {
  .dices {
    /* max-width: 900px; */
  }
}

